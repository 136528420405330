<template>
    <CardComponent :title="title" :icon="icon">
        <section>
            <el-tabs v-if="contentData.length > 0" tab-position="left">
                <el-tab-pane
                    v-for="(item, itemIndex) in contentData"
                    :key="itemIndex"
                    :label="item.title"
                >
                    <ul class="content">
                        <li v-for="(data, index) in item.datas" :key="index">
                            <el-link :underline="false" target="_blank">{{
                                data.distributorName
                            }}</el-link>
                        </li>
                    </ul>
                </el-tab-pane>
            </el-tabs>
            <Empty text="开发中..." v-else />
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import Empty from '@/components/empty'
export default {
    name: '',
    components: { CardComponent, Empty },
    data() {
        return {
            title: '待招岗位',
            icon: 'iconfont icon-daizhaogangwei',
            contentData: []
        }
    },
    created() {},
    methods: {}
}
</script>
<style lang="less" scoped>
// @import url(); 引入公共css类
</style>
